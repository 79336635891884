import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import ContentPage from "../../../layouts/contentPage"

import Seo from "../../../components/seo"

import ProductHero from "../../../components/Product/productHero"
import H1 from "../../../components/h1"
import ButtonColor from "../../../components/buttonColor"
import ProductInfo from "../../../components/Product/productInfo"
import ProductPackshot from "../../../components/Product/productPackshot"
import ProductPackshotBubbles from "../../../components/Product/productPackshotBubbles"
import BubbleBg from "../../../components/Product/bubbleBg"
import ProductSidebar from "../../../components/Product/productSidebar"
import More from "../../../components/more"
import Tabs from "../../../components/Tabs/tabs"
import ProductDescription from "../../../components/Product/productDescription"
import Energyze from "../../../images/media/products/energyze/energyze.svg";
import Ingredients from "../../../components/Product/ingredients"
import Ingredient from "../../../components/Product/ingredient"
import Effectivness from "../../../components/Product/effectivness"
import EffectivnessCard from "../../../components/Product/effectivnessCard"
import ActiveIngredients from "../../../components/Product/activeIngredients"
import ActiveIngredient from "../../../components/Product/activeIngredient"
import DescriptionPackshot from "../../../components/Product/descriptionPackshot"
import Container from "/src/components/Product/container"






const Product = () => (
    <Layout lang={"EN"}>
      <Seo title="Normalizing repair serum - Energyze" lang={"EN"}/>
        <ProductHero>

            {/* Info */}
            <div data-column>
                <ProductInfo color="lime">
                    <H1>Normalizing repair serum </H1>
                    <p>Skin Balance & Texture  + Uniformity & Glow </p>
                    <ProductPackshot mobile>
                        <StaticImage
                        src="../../images/media/products/energyze/products/normalizujace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="Normalizing repair serum "
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                    <ul>
                        <li>Breaks down sebum and removes sebum plugs to unclog pores. </li>
                        <li>Regulates over production of blemish-causing sebum to allow supply of nutrients for active growth of new skin cells.</li>
                        <li>Oxygenates to let skin cells do what they do best. Enhances microbiome and restores skin natural  protective barrier.</li>
                    </ul>
                    <Container>
                    <ButtonColor as="a" target="_blank" href="https://www.hebe.pl/oxygenetic-normalizujace-serum-naprawcze-do-twarzy-50-ml-000000000000407507.html" color="lime">Buy in Hebe</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://www.zikodermo.pl/oxygenetic-energyze-normalizujace-serum-naprawcze-50-ml.html" color="lime">Buy in Ziko Dermo</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://gemini.pl/oxygenetic-energyze-vitamin-c-normalizujace-serum-naprawcze-50-ml-0107785" color="lime">Buy in Gemini</ButtonColor>
                    </Container>                
                    </ProductInfo>
            </div>

            {/* Packshot */}
            <div data-column>
                <ProductPackshot>
                        <StaticImage
                        src="../../images/media/products/energyze/products/normalizujace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="normalizujace serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                <More url="#opis-produktu" color="lime" text="more"/>
            </div>

            {/* Category Products */}
            <div data-column>
                <ProductSidebar lang={"EN"}>
                    <Link to="/en/products/revitalizing-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/rewitalizujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="nawilzajace-serum"
                        />
                    </Link>
                    <Link to="/en/products/oxygenating-sorbet">
                        <StaticImage
                            src="../../images/media/products/energyze/products/nawilzajacy-sorbet.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="nawilzajace-serum"
                        />
                    </Link>
                    <Link to="/en/products/regenerating-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/regeneracyjne-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="regeneracyjne serum"
                        />
                    </Link>
                    <Link to="/en/products/normalizing-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/normalizujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="normalizujace serum"
                        />
                    </Link>

                </ProductSidebar>
            </div>
            {/* <BubbleBg/> */}
        </ProductHero> 
        <section id="opis-produktu">

            {/* TABS */}
            <Tabs color="lime">

                {/* OPIS */}
                <div label="Description" className="tab-list-active">
                <ProductDescription color="lime">
                    <div className="descriptionRow">
                        <div className="column">
                            <div className="text--left">

                                <p>
                                    <strong>Oxygenetic normalizing repair serum</strong>
                                </p>
                                <p>
                                  When skin texture is affected by large pores and nasty excess oily and waxy sebum, this serum regulates sebum and minimises large pores for anti-shine effect.
                                </p>
                                <p>
                                    While it restores balance, it also hydrates, nourishes and energises skin for much stronger protecive barrier. This sebum has been crafted to balance oiliness and pores and give skin a flawless and radiant smile.
                                </p>
                                <p>
                                    Its lightweight formula absorbs quickly to let skin breathe.
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <DescriptionPackshot>
                                <StaticImage
                                src="../../images/media/products/energyze/products/normalizujace-serum_single.png"
                                loading="lazy"
                                width={534}
                                quality={95}
                                placeholder="none"
                                formats={["auto", "webp", "avif"]}
                                alt="Energetyzujące serum"
                             />
                            </DescriptionPackshot>
                        </div>
                        <div className="column">
                            <div className="text--right">
                                <p><strong>How to use:</strong></p>
                                <p>Apply at night before bed. Cleanse your skin before using. For external use only. Avoid contact with eyes.</p>
                                <br/>
                                <p><strong>Volume:</strong></p>
                                <p>50ml</p>
                                <Energyze/>
                            </div>
                        </div>
                    </div>        
                </ProductDescription>
                </div>

                {/* BAZA */}
                <div label="Base">
                <Ingredients>
                    <Ingredient name="Vitamin C" desc="A powerhouse antioxidant which neutralises free radicals harmful to skin cells. Slows aging process in the body and shields against environmental stressors. Lightens pigementation and revives the skin due to enhanced collagen and elastin synthesis." />
                    <Ingredient name="Blend of oxygentaing minerals" desc="Minerals are the component which binds oxygen molecules which are absorbed by the skin and stimulates metabolic processes in the skin so that more energy is released and skin cells start to function properly for healthy and radiant look." />
                    <Ingredient name="Synbiotics" desc="A blend of prebiotics and probiotics which fortify microbiom, our natural protective barrier, to protect against environmental stressors. Hydrate, nourish and smooth your skin." />
                </Ingredients>
                </div>

                {/* SKŁADNIKI AKTYWNE */}
                <div label="Active ingredients">
                <ActiveIngredients>
                    <ActiveIngredient name="Enantia Chlorantha bark extract and oleanoic acid" desc="work to regulate sebaceous glands to balance oiliness and reduce sebum secretion for matysoft effect. Minimise enlarged pores and reduce shine to keep the skin feeling fresh and youthful." />
                </ActiveIngredients>
                </div>
        
                {/* SKUTECZNOŚĆ */}
                <div label="Effectivness">

                <Effectivness>
                    <EffectivnessCard color="lime" percentage="93" desc="badanych potwierdza, że produkt działa w głębszych warstwach skóry skutecznie ją regenerując"/>
                    <EffectivnessCard color="lime" percentage="100" desc="badanych potwierdza, że produkt zmniejsza rozszerzone pory"/>
                    <EffectivnessCard color="lime" percentage="93" desc="badanych potwierdza, że produkt zapewnia długotrwały efekt matowej skóry"/>
                </Effectivness>
                </div>
            </Tabs>
        </section>
    </Layout>
)

export default Product
